import React, { useState, useContext, useEffect } from 'react';
import { Icon, LegacyCard, TextField } from '@shopify/polaris';
import { AbandonedCartMajor } from '@shopify/polaris-icons';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { ViewMajor } from '@shopify/polaris-icons';

export const Login = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [showPass, setShowPass] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const navigate = useNavigate();

  const { user, login } = useContext(AuthContext);

  useEffect(() => {
    if (user?.access) {
      navigate('/');
    }
  }, [user]);

  const onSubmit = async (data: any) => {
    const { email, password } = data;
    const res = await login(email, password);
    if (res?.access) {
      navigate('/');
    } else {
      setError(true);
    }
  };

  return (
    <div className="relative">
      <div className="absolute left-28 top-2 h-16 w-16 ">
        <img src="/water-droplet-icon.svg" width={50} />
      </div>
      <div className="absolute left-0 xl:top-36 2xl:top-20">
        <img src="/orange.svg" className="xl:w-[819px] xl:h-auto 2xl:w-[980px] 2xl:h-[780px]" />
      </div>
      <div className="absolute xl:right-20 xl:top-96 2xl:right-10 2xl:top-48">
        <img src="/background.svg" className="xl-w-[800px] xl:h-[400px] 2xl:w-[900px] 2xl:h-[700px]" />
      </div>
      <div className="absolute xl:right-16 xl:top-52 2xl:top-32 2xl:right-52">
        <div className="relative">
          <img src="/blueImg.svg" className=" absolute xl-w-96 xl:h-auto" />
          <div
            className=" relative top-10 left-4 font-bold xl:text-[40px] 2xl:text-[40px]"
            style={{ fontFamily: 'Poppins' }}
          >
            Start your journey now with
          </div>
        </div>
      </div>
      <div className="absolute xl:right-20 xl:top-80 2xl:right-56 2xl:top-56">
        <span className=" font-bold xl:text-[40px] 2xl:text-[40px]" style={{ fontFamily: 'Poppins' }}>
          our{' '}
        </span>
        <span
          className=" font-bold xl:text-[40px] 2xl:text-[40px]"
          style={{ color: 'rgb(31 111 230)', fontFamily: 'Poppins' }}
        >
          management system
        </span>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="absolute xl:left-40 xl:top-56 2xl:left-56 2xl:top-36">
          <div className=" w-[443px] rounded-[12px] bg-white">
            <div className="flex justify-center">
              <img src="/water-droplet-icon.svg" width={90} className="mt-8" />
            </div>
            <div
              className="flex justify-center mt-8 xl:text-[20px]"
              style={{ fontFamily: 'Poppins', fontWeight: '500' }}
            >
              Welcome to Auto Flow!
            </div>
            <div className="flex justify-center mt-4 xl:text-[14px]" style={{ color: '#8B8D97' }}>
              Login to your account
            </div>
            <div className="flex justify-center mt-14">
              <div className="relative mb-6">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <img src="/Message.svg" />
                </div>
                <input
                  {...register('email')}
                  style={{ backgroundColor: 'rgba(239, 241, 249, 0.6)' }}
                  type="email"
                  id="input-group-1"
                  className="w-96 outline-0 text-sm rounded-lg pl-10 p-3 "
                  placeholder="Email Address"
                />
              </div>
            </div>
            <div className="flex justify-center">
              <div className="relative mb-6">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <img src="/Lock.svg" />
                </div>
                <input
                  {...register('password')}
                  style={{ backgroundColor: 'rgba(239, 241, 249, 0.6)' }}
                  type={showPass ? 'text' : 'password'}
                  id="input-group-1"
                  className="w-96 outline-0  text-sm rounded-lg  pl-10 p-3"
                  placeholder="Password"
                />
                <div
                  className="absolute inset-y-0 right-3 flex items-center pl-3 cursor-pointer"
                  onClick={() => setShowPass(!showPass)}
                >
                  {showPass ? <img src="/fi_eye-off.svg" /> : <Icon source={ViewMajor} color="base" />}
                </div>
              </div>
            </div>
            {error && (
              <div className="italic ml-6 mt-4 text-[10px]" style={{ color: '#D82C0D' }}>
                Enter a valid email address or password!
              </div>
            )}

            <div className="text-right mr-8 text-[14px] mt-2" style={{ color: '#5570F1' }}>
              Recover Password
            </div>
            <div className="flex justify-center mt-10">
              <div style={{ color: '#8B8D97' }}>Don’t have an account?</div>
              <div className="ml-2" style={{ color: '#5570F1' }}>
                {' '}
                Sign Up
              </div>
            </div>
            <div className="flex justify-center ">
              <button
                type="submit"
                className="mt-10 mb-12 pl-16 pr-16 pt-3 pb-3 rounded-[12px] text-white"
                style={{ backgroundColor: '#5570F1' }}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
