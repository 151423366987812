import { Navigate } from 'react-router-dom';
import Dashboard from '../components/dashboard/Dashboard';
import UserManagement from '../components/user-management/UserManagement';
import AppManagement from '../components/app-management/AppManagement';
import UserProfile from '../components/user-management/UserProfile';
import DailyWater from '../components/daily-water/DailyWater';

export const routes = [
  { path: `/`, Component: <DailyWater /> },
  // { path: `/`, Component: <Navigate to="/user-management" replace /> },
  // { path: `/dashboard/`, Component: <Dashboard /> },
  // { path: `/metric/`, Component: <UserManagement /> },
  // { path: `/app-management/`, Component: <AppManagement /> },
  // { path: `/user-management/`, Component: <UserManagement /> },
  // { path: `/user-profile/`, Component: <UserProfile /> },
];
