import React, { Fragment } from 'react';
// import Loader from "../layout/loader";
// import Taptop from "../layout/tap-top";
// import Header from '../layout/header'
// import Sidebar from '../layout/sidebar'
// import Footer from '../layout/footer'
// import ThemeCustomize from "../layout/theme-customizer";
// import { ToastContainer } from 'react-toastify'
import { Outlet } from 'react-router-dom';
import { LeftMenu } from './LeftMenu';
import Header from './Header';

const AppLayout = () => {
  return (
    <Fragment>
      <Header />
      <div className="flex">
        {/* <aside className="h-screen sticky top-0 w-60">
          <LeftMenu />
        </aside> */}
        <main className="w-full">
          <Outlet />
        </main>
      </div>
    </Fragment>
  );
};

export default AppLayout;
