import axios from "axios";
import { UserModel } from "../models/User";

class AuthService {
  setUserInLocalStorage(data: any) {
    localStorage.setItem("user", JSON.stringify(data));
  }

  async login(email: string, password: string): Promise<any> {

    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/login/`, {
      email,
      password,
    });
    this.setUserInLocalStorage(data);
    return data;
  }

  logout() {
    localStorage.removeItem("user");
  }

  getCurrentUser() {
    const user = localStorage.getItem("user")!;
    return JSON.parse(user);
  }
}

export default new AuthService();
