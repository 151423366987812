import React from 'react';
import './App.scss';
import '@shopify/polaris/build/esm/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import { Login } from './components/auth/Login';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthContextProvider } from './contexts/AuthContext';
import { ProtectedRoute } from './contexts/ProtectedRoute';
import LayoutRoutes from './route/LayoutRoutes';

function App() {
  return (
    <BrowserRouter>
      <AuthContextProvider>
        <Routes>
          <Route
            path="/*"
            element={
              <ProtectedRoute>
                <LayoutRoutes />
              </ProtectedRoute>
            }
          />
          <Route path={`/login`} element={<Login />} />
        </Routes>
      </AuthContextProvider>
    </BrowserRouter>
  );
}

export default App;
