import { LegacyCard, IndexTable, useIndexResourceState, Text, Button, DataTable, Icon } from '@shopify/polaris';
import React, { useContext, useEffect, useState } from 'react';
import {
  PieChart,
  Pie,
  Legend,
  Cell,
  ResponsiveContainer,
  Label,
  Tooltip,
  AreaChart,
  CartesianGrid,
  Area,
  YAxis,
  XAxis,
} from 'recharts';
import { AuthContext } from '../../contexts/AuthContext';
import { DeleteMajor } from '@shopify/polaris-icons';
import LoadingSpinner from '../common/Loading';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#189832', '#982718', '#009688'];

const ColorType: any = {
  shower_24_hour_volume: '#0088FE',
  tap_24_hour_volume: '#00C49F',
  clotheswasher_24_hour_volume: '#FFBB28',
  irrigation_24_hour_volume: '#FF8042',
  dishwasher_24_hour_volume: '#189832',
  other_24_hour_volume: '#982718',
  toilet_24_hour_volume: '#009688',
};

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#ffff',
          padding: '5px',
          border: '1px solid #cccc',
        }}
      >
        <label>{`${payload[0].name} : ${payload[0].value}(Litres)`}</label>
      </div>
    );
  }
};

interface Item {
  hour_0: number;
  hour_1: number;
  hour_2: number;
  hour_3: number;
  hour_4: number;
  hour_5: number;
  hour_6: number;
  hour_7: number;
  hour_8: number;
  hour_9: number;
  hour_10: number;
  hour_11: number;
  hour_12: number;
  hour_13: number;
  hour_14: number;
  hour_15: number;
  hour_16: number;
  hour_17: number;
  hour_18: number;
  hour_19: number;
  hour_20: number;
  hour_21: number;
  hour_22: number;
  hour_23: number;
  sum_water: number;
  name: string;
}

const DailyWater = () => {
  const { authAxios, user } = useContext(AuthContext);
  const [fileUser, setFileUser] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [dataTable, setDataTable] = useState<object[]>([]);
  const [dataChart, setDataChart] = useState<object[]>([]);
  const [totalVolume, setTotalVolume] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);

  const getAllFileByUser = async () => {
    const userId = user?.userId;
    const { data } = await authAxios.get(`/api/csv-file/${userId}`);
    setFileUser(data);
  };

  const getDailyWater = async (fileId: any) => {
    const { data } = await authAxios.get(`/api/daily-water/${fileId}`);
    setDataTable(data);
  };

  const handleFileChange = async (event: any) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('user', '1');
    formData.append('file', event.target.files[0]);
    await authAxios.post('/api/upload-file/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    await getAllFileByUser();
    setIsLoading(false);
  };

  useEffect(() => {
    getAllFileByUser();
  }, []);

  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(fileUser);

  const handleAnalyst = async () => {
    setIsLoading(true);
    const { data } = await authAxios.post('/api/process-file', { file_id: selectedResources });
    setIsLoading(false);
    const result: any[] = [];
    data?.forEach((dataItem: any) => {
      dataItem.forEach((item: any) => {
        result.push(item);
      });
    });
    const shower = result.filter(item => item.water_type == 'shower_24_hour_volume');
    const tap = result.filter(item => item.water_type == 'tap_24_hour_volume');
    const clothes = result.filter(item => item.water_type == 'clotheswasher_24_hour_volume');
    const irrigation = result.filter(item => item.water_type == 'irrigation_24_hour_volume');
    const other = result.filter(item => item.water_type == 'other_24_hour_volume');
    const toilet = result.filter(item => item.water_type == 'toilet_24_hour_volume');
    const dishwater = result.filter(item => item.water_type == 'dishwasher_24_hour_volume');

    const resultShower: { [key: string]: number } = {};

    shower.forEach((item: any) => {
      Object.keys(item).forEach(key => {
        if (key == 'created_at' || key == 'water_type') {
          resultShower[key] = item[key];
        } else {
          resultShower[key] = (resultShower[key] || 0) + Number(item[key]);
        }
      });
    });
    const resultTap: { [key: string]: number } = {};

    tap.forEach((item: any) => {
      Object.keys(item).forEach(key => {
        if (key == 'created_at' || key == 'water_type') {
          resultTap[key] = item[key];
        } else {
          resultTap[key] = (resultTap[key] || 0) + Number(item[key]);
        }
      });
    });

    const resultClothes: { [key: string]: number } = {};

    clothes.forEach((item: any) => {
      Object.keys(item).forEach(key => {
        if (key == 'created_at' || key == 'water_type') {
          resultClothes[key] = item[key];
        } else {
          resultClothes[key] = (resultClothes[key] || 0) + Number(item[key]);
        }
      });
    });
    const resultIrrigation: { [key: string]: number } = {};

    irrigation.forEach((item: any) => {
      Object.keys(item).forEach(key => {
        if (key == 'created_at' || key == 'water_type') {
          resultIrrigation[key] = item[key];
        } else {
          resultIrrigation[key] = (resultIrrigation[key] || 0) + Number(item[key]);
        }
      });
    });
    const resultOther: { [key: string]: number } = {};

    other.forEach((item: any) => {
      Object.keys(item).forEach(key => {
        if (key == 'created_at' || key == 'water_type') {
          resultOther[key] = item[key];
        } else {
          resultOther[key] = (resultOther[key] || 0) + Number(item[key]);
        }
      });
    });
    const resultToilet: { [key: string]: number } = {};

    toilet.forEach((item: any) => {
      Object.keys(item).forEach(key => {
        if (key == 'created_at' || key == 'water_type') {
          resultToilet[key] = item[key];
        } else {
          resultToilet[key] = (resultToilet[key] || 0) + Number(item[key]);
        }
      });
    });
    const resultDishwater: { [key: string]: any } = {};

    setDataTable([
      resultShower,
      resultTap,
      resultClothes,
      resultIrrigation,
      resultToilet,
      resultDishwater,
      resultOther,
    ]);
    dataTable.forEach((item: any) => {
      setTotalVolume(totalVolume + item.sum_water);
    });

    dishwater.forEach((item: any) => {
      Object.keys(item).forEach(key => {
        if (key == 'created_at' || key == 'water_type') {
          resultDishwater[key] = item[key];
        } else {
          resultDishwater[key] = (resultDishwater[key] || 0) + Number(item[key]);
        }
      });
    });

    const resultChart = [];
    for (let i = 0; i < 24; i += 1) {
      const myobj = {
        name: i,
        shower_24_hour_volume: resultShower[`hour_${i}`],
        tap_24_hour_volume: resultTap[`hour_${i}`],
        clotheswasher_24_hour_volume: resultClothes[`hour_${i}`],
        irrigation_24_hour_volume: resultIrrigation[`hour_${i}`],
        dishwasher_24_hour_volume: resultDishwater[`hour_${i}`],
        other_24_hour_volume: resultOther[`hour_${i}`],
        toilet_24_hour_volume: resultToilet[`hour_${i}`],
      };
      resultChart.push(myobj);
    }
    setDataChart(resultChart);
    // console.log(resultChart);

    // await getDailyWater(selectedResources);
  };

  const handleDeleteFile = async () => {
    setIsLoading(true);
    const data = await authAxios.post('/api/delete-files/', { file_ids: selectedResources });
    getAllFileByUser();
    setIsLoading(false);
  };

  return (
    <div className="p-5 space-y-3">
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2">
        <div className="col-span-1">
          <LegacyCard>
            <div className="p-5">
              <div className="flex justify-center mb-5">
                <div className="flex items-center justify-center w-full">
                  <label className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg
                        aria-hidden="true"
                        className="w-10 h-10 mb-3 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        ></path>
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">Click to upload</span> or drag and drop
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">CSV</p>
                    </div>
                    <input type="file" accept=".csv" onChange={handleFileChange} className="hidden" />
                  </label>
                </div>
              </div>
              {isLoading ? (
                <div className="flex justify-center items-center">
                  {' '}
                  <LoadingSpinner />
                </div>
              ) : (
                <IndexTable
                  // resourceName={resourceName}
                  itemCount={fileUser.length}
                  selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
                  onSelectionChange={handleSelectionChange}
                  headings={[{ title: 'Id' }, { title: 'File Name' }]}
                >
                  {fileUser.map(({ id, file }: any, index) => (
                    <IndexTable.Row id={id} key={id} selected={selectedResources.includes(id)} position={index}>
                      <IndexTable.Cell>{id}</IndexTable.Cell>
                      <IndexTable.Cell>
                        <Text variant="bodyMd" fontWeight="bold" as="span">
                          {file?.split('/')[3]}
                        </Text>
                      </IndexTable.Cell>
                      <IndexTable.Cell></IndexTable.Cell>
                    </IndexTable.Row>
                  ))}
                </IndexTable>
              )}
            </div>
            <div className="flex justify-center pb-5 space-x-5">
              <Button onClick={() => handleAnalyst()} primary>
                Analyse
              </Button>
              {selectedResources.length > 0 && (
                <Button onClick={() => handleDeleteFile()} destructive>
                  Delete
                </Button>
              )}
            </div>
          </LegacyCard>
        </div>
        <div className="col-span-1 h-96">
          <LegacyCard title="Overall end-use breakdown">
            <div className="w-100 md:w-auto h-[475px]">
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    data={dataTable.map((item: any) => {
                      return {
                        name: item.water_type?.split('_')[0],
                        value: Number(item.sum_water),
                      };
                    })}
                    dataKey="value"
                    // cx={200}
                    // cy={200}
                    innerRadius={60}
                    outerRadius={100}
                  >
                    <Cell fill={ColorType.shower_24_hour_volume} />
                    <Cell fill={ColorType.tap_24_hour_volume} />
                    <Cell fill={ColorType.clotheswasher_24_hour_volume} />
                    <Cell fill={ColorType.irrigation_24_hour_volume} />
                    <Cell fill={ColorType.dishwasher_24_hour_volume} />
                    <Cell fill={ColorType.other_24_hour_volume} />
                    <Cell fill={ColorType.toilet_24_hour_volume} />
                    {/* <Label content={<CustomLabel value={15} />} position="center" /> */}
                  </Pie>
                  <Tooltip content={CustomTooltip} />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </LegacyCard>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2">
        <div className="w-100 md:w-auto h-[475px]">
          <LegacyCard>
            <div className="w-100 md:w-auto h-[468px]">
              <ResponsiveContainer>
                <AreaChart data={dataChart} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="shower_24_hour_volume"
                    stroke="#8884d8"
                    fillOpacity={1}
                    fill={ColorType.shower_24_hour_volume}
                  />
                  <Area
                    type="monotone"
                    dataKey="tap_24_hour_volume"
                    stroke="#8884d8"
                    fillOpacity={1}
                    fill={ColorType.tap_24_hour_volume}
                  />
                  <Area
                    type="monotone"
                    dataKey="clotheswasher_24_hour_volume"
                    stroke="#8884d8"
                    fillOpacity={0.2}
                    fill={ColorType.clotheswasher_24_hour_volume}
                  />
                  <Area
                    type="monotone"
                    dataKey="irrigation_24_hour_volume"
                    stroke="#8884d8"
                    fillOpacity={0.7}
                    fill={ColorType.irrigation_24_hour_volume}
                  />
                  <Area
                    type="monotone"
                    dataKey="dishwasher_24_hour_volume"
                    stroke="#8884d8"
                    fillOpacity={1}
                    fill={ColorType.dishwasher_24_hour_volume}
                  />
                  <Area
                    type="monotone"
                    dataKey="other_24_hour_volume"
                    stroke="#8884d8"
                    fillOpacity={1}
                    fill={ColorType.other_24_hour_volume}
                  />
                  <Area
                    type="monotone"
                    dataKey="toilet_24_hour_volume"
                    stroke="#8884d8"
                    fillOpacity={0.2}
                    fill={ColorType.toilet_24_hour_volume}
                  />

                  {/* <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" /> */}
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </LegacyCard>
        </div>
        <div>
          <LegacyCard title="Volume">
            <div>
              <DataTable
                columnContentTypes={['text', 'numeric']}
                headings={['Category', 'Volume (Litres)']}
                rows={dataTable.map((item: any) => [item.water_type.split('_')[0], Number(item.sum_water).toFixed(2)])}
                // totals={['', '', '', 255, '$155,830.00']}
              />
            </div>
          </LegacyCard>
        </div>
      </div>
    </div>
  );
};

export default DailyWater;
