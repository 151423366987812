import React, { useCallback, useState, useContext } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';

const Header = () => {
  const { user, logout } = useContext(AuthContext);

  return (
    <nav className="w-full bg-white border-b justify-between flex-wrap p-1  m-auto sticky top-0 z-50">
      <div className="flex bg-white justify-between">
        <div className="ml-2 flex items-center space-x-3">
          <img src="/water-droplet-icon.svg" width={34} />
          <div className="font-bold text-lg">AutoFlow</div>
        </div>
        <Menu as="div" className="px-3 relative block text-center">
          {({ open }) => (
            <>
              <div>
                <Menu.Button className="flex items-center cursor-pointer">
                  <img src="/Avatar.svg" className="rounded-full w-12 " alt="Avatar" />
                  <div
                    // style={{ color: dark ? 'black' : 'white', height: '48px', width: '150px' }}
                    className="mb-0 ml-2 items-center user-email flex text-overflow"
                  >
                    {user?.username}
                    {/* Admin@gmail.com */}
                  </div>
                </Menu.Button>
              </div>
              <Transition
                // show={open}
                // as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="absolute right-0 mt-4 w-32 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none "
                >
                  <Menu.Item>
                    <Link to="/user-profile" className="flex items-center cursor-pointer px-4 py-2 text-gray-700">
                      Your profile
                    </Link>
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={logout}
                        className={'block px-4 py-2 text-sm text-gray-700 cursor-pointer text-left'}
                      >
                        Logout
                      </div>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    </nav>
  );
};
export default Header;
