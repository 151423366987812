import { AxiosRequestHeaders } from "axios";

export default function authHeader(): any {
  const localStorageUser = localStorage.getItem("user");
  if (!localStorageUser) {
    return {};
  }
  const user = JSON.parse(localStorageUser);
  if (user && user.access) {
    return { Authorization: `Bearer ${user.access}` };
  }
  return {};
}
